<template>
    <div class="student-wrapper">
        <div class="header">
            <div class="head-btn">
                <el-button class="train-manage" @click="goToTrainManage">训练管理</el-button>
                <el-button class="train-manage blue" @click="dialogVisible = true">提交重置数据</el-button>
            </div>
            <div class="header-left">
                <!--倒计时-->
                <span style="margin-right: 10px;">{{timeText}}</span>
                <time-count-down :left-time="leftTime" @change="syncTime"></time-count-down>
            </div>
            <div class="header-right">
                <div class="exit-btn" @click="goBack">
                    <i class="iconfont">&#xe648;</i>
                    <div>返回主页面</div>
                </div>
            </div>
        </div>
        <div class="train-content">
            <div class="inner-content">
                <div class="inner-head">{{name}}</div>
                <el-scrollbar>
                    <div class="train-item" v-for="(trainItem,trainIndex) in trainExamInfo.totalPage" :key="trainIndex">
                        <div class="shadow-box" @click="intoTrain(trainItem)">
                            <div class="img-box">
                                <img src="../../assets/images/studentIndex/train_list.png" alt="">
                            </div>
                            <div class="train-num">
                                <span class="current-train">{{trainItem}}</span>
                                <span class="train-each">/{{trainExamInfo.totalPage}}</span>
                            </div>
                            <div class="enter-text">进入训练</div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="endDialog" width="550px" custom-class="end-dialog" @close="studentLogout">
            <div class="end-competition">
                <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                <div class="text">训练已结束~</div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" class="btn-red" @click="studentLogout">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="608px">
            <div class="train-dialog-content">
                <span>1.重置训练将会清空当前训练数据，学生可以重新开始训练</span>
                <span>2.每一次训练成绩详情可在训练管理进行查看</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="restData" class="btn-red">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {studentTrainLoading, studentTrainTrainReset, stuLogout} from '@/utils/apis'
    import common from "@/utils/common";
    import TimeCountDown from "@/components/plugs/timeCountDown";

    export default {
        components: {TimeCountDown},
        data() {
            return {
                username: '',
                userIcon: '',
                //比赛介绍
                introduce: '',
                //比赛名称
                name: '',
                //比赛时间
                beginTime: '',
                endTime: '',
                isStart: true,
                // 比赛结束弹窗
                endDialog: false,
                dialogVisible: false,
                trainInfo: {},
                countDownList: '',
                timeText: '',
                trainExamInfo: {},
                timesId: null,
                leftTime: 0,
            }
        },
        mounted() {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            if (this.username === null) {
                this.$message({
                    type: 'warning',
                    message: '请先登录',
                    onClose: () => {
                        this.studentLogout();
                    }
                });
            }
            this.getStudentComeInfo();
        },
        methods: {
            syncTime(val) {
                this.leftTime = Number(val);
                if (this.leftTime < 0 && this.isStart) {
                    this.isStart = false;
                    this.leftTime = this.endTime - this.beginTime;
                }
                if (this.leftTime < 0) {
                    this.$message({
                        type: "error",
                        message: '训练结束',
                        duration: 3000,
                        onClose: () => {
                            localStorage.clear();
                            this.$router.push('/');
                        }
                    });

                }
            },
            changeStatusCompetition(val) {
                this.isStart = val;
            },
            //获取比赛信息
            getStudentComeInfo() {
                let now_time = Date.parse(new Date());
                this.$http.axiosGet(this.$api.studentComInfo, (res) => {
                    if (res.code === 200) {
                        if (res.data.train) {
                            this.trainInfo = res.data.train
                            this.getTrainDetail()
                            this.name = res.data.train.train_name;
                            let response_time = Date.parse(new Date());
                            let process_time = (response_time - now_time) / 1000;
                            let current_time = Number(res.data.train.now) - process_time;
                            //获取开始时间
                            this.beginTime = Number(res.data.train.start_time);
                            this.endTime = Number(res.data.train.end_time);
                            if (this.beginTime > current_time) {
                                this.leftTime = this.beginTime - current_time;
                                this.timeText = "距离训练开始：";
                                this.isStart = true;
                            }
                            if (this.endTime >= current_time) {
                                this.leftTime = this.endTime - current_time;
                                this.isStart = false;
                                this.timeText = "距离训练结束：";
                            }
                        } else {
                            this.$message({
                                type: 'warning',
                                message: '当前没有理论题实训或者考试',
                                duration: 1500,
                                onClose: () => {
                                    this.$router.push('/student/index');
                                }
                            });
                        }
                    } else {
                        this.$message.warning(res.msg)
                        this.endDialog = true
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            getTrainDetail() {
                let params = {
                    train_id: this.trainInfo.id,
                }
                studentTrainLoading(params).then(res => {
                    if (res.code == 200) {
                        this.trainExamInfo = res.data
                        this.timesId = res.data.times_id;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            goBack() {
                this.$router.push('/student/index')
            },
            //退出
            studentLogout() {
                stuLogout().then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            onClose: () => {
                                this.$lockr.flush();
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/') {
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        });
                    } else {
                        this.$router.push('/');
                    }

                }).catch(err => {
                    console.log(err);
                })
            },
            goToTrainManage() {
                this.$router.push({path: "/student/trainmanage/studenttrainlist"});
            },
            restData() {
                let params = {
                    train_id: this.trainInfo.id,
                }
                studentTrainTrainReset(params).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.dialogVisible = false
                        this.getStudentComeInfo();
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            countDown(left_time, before_begin_time) {
                if (left_time < 0) {
                    // 比赛结束
                    clearInterval(this.countDownInstance);
                    this.endDialog = true
                }
                if (before_begin_time > 0) {
                    this.timeText = `距离训练结束：`;
                    console.log('left_time', left_time);
                    this.countDownList = this.$common.formatTimeCount(left_time);
                }
                if (before_begin_time < 0 && left_time > 0) {
                    this.timeText = `距离训练开始：`;
                    this.countDownList = this.$common.formatTimeCount(Math.abs(before_begin_time));
                }
            },
            intoTrain(page) {
                if (this.isStart) {
                    this.$message.warning('训练还未开始');
                    return;
                }
                let query = {
                    page: page,
                    times_id: this.timesId,
                    id: this.trainExamInfo.train_id,
                    total: this.trainExamInfo.totalPage
                };
                // console.log('train', query);
                this.$router.push({path: '/student/theorytrain/index', query});
            }
        }
    }
</script>

<style scoped lang="scss">
    .student-wrapper {
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;

            .head-btn {
                height: 100%;
                margin-left: 24px;
                display: flex;
                align-items: center;
            }

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                color: #FD4446;
                font-weight: 500;
                font-size: 18px;
            }

            .train-manage {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                color: #FD4446;

                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }

                &.blue {
                    border: unset;
                    color: #fff;
                    background: #1122D8;
                    border-radius: 4px;
                }
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 24px;
                        color: #1122D8;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .train-content {
            height: calc(100vh - 60px);
            width: 100%;
            background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
            background-size: cover;
            display: flex;
            justify-content: center;

            .inner-content {
                padding: 76px 175px;
                height: calc(100% - 252px);
                width: 100%;

                .inner-head {
                    text-align: center;
                    font-weight: 500;
                    font-size: 30px;
                    color: #FFFFFF;
                }

                ::v-deep .el-scrollbar {
                    height: 100%;
                    margin-top: 100px;

                    .el-scrollbar__wrap {
                        overflow-x: hidden;

                        .el-scrollbar__view {
                            height: 100%;
                            width: calc(100% - 10px);
                            margin-left: 10px;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                }

                .train-item {
                    width: calc(20% - 70px);
                    height: calc(50% - 50px);
                    background: #FFFFFF;
                    box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.08);
                    border-radius: 8px;
                    margin: 10px 70px 50px 0;
                    min-height: 300px;
                    position: relative;

                    &:nth-of-type(5n) {
                        margin-right: 0;
                    }

                    .shadow-box {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background: #fff;
                        box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.08);
                        border-radius: 8px;
                    }

                    &:hover {
                        cursor: pointer;

                        .shadow-box {
                            top: -10px;
                            left: -10px;
                            height: calc(100% + 20px);
                            width: calc(100% + 20px);
                        }
                    }

                    .img-box {
                        margin-top: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .train-num {
                        text-align: center;
                        font-family: BigYoungBold;

                        .current-train {
                            font-size: 48px;
                            color: #1122D8;
                        }

                        .train-each {
                            font-size: 18px;
                            color: #000000;
                        }
                    }

                    .enter-text {
                        text-align: center;
                        font-size: 18px;
                        color: #000000;
                        font-weight: 500;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    ::v-deep .end-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #eee;
        }

        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #FD4446;
        }

        .end-competition {
            text-align: center;

            img {
                height: 170px;
            }

            .text {
                margin-top: 28px;
                color: #666;
                font-size: 18px;
            }
        }
    }

    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
</style>